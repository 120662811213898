var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"sign--set"},[_c('div',{staticClass:"sign--set--main"},[_c('div',{staticClass:"sign--set--left"},[_c('vh-form',{ref:"signSetForm",attrs:{"model":_vm.signSetForm,"rules":_vm.signSetFormRules,"label-width":"66px"}},[_c('vh-form-item',{attrs:{"label":"主办方","prop":"organizers_status"}},[_c('div',{staticClass:"switch__box"},[_c('vh-switch',{attrs:{"active-value":1,"inactive-value":0,"active-color":"#FB3A32","inactive-color":"#CECECE","active-text":_vm.signSetForm.organizers_status
                  ? '已开启，观看页显示主办方信息、个人主页按钮和关注按钮'
                  : '开启后，观看页显示主办方信息、个人主页按钮和关注按钮'},model:{value:(_vm.signSetForm.organizers_status),callback:function ($$v) {_vm.$set(_vm.signSetForm, "organizers_status", $$v)},expression:"signSetForm.organizers_status"}})],1)]),_c('vh-form-item',{attrs:{"label":"版权","prop":"reserved_status"}},[_c('div',{staticClass:"switch__box"},[_c('vh-switch',{attrs:{"active-value":1,"inactive-value":0,"active-color":"#FB3A32","inactive-color":"#CECECE","active-text":_vm.signSetForm.reserved_status
                  ? '已开启，观看页显示底部版权信息'
                  : '开启后，观看页显示底部版权信息'},model:{value:(_vm.signSetForm.reserved_status),callback:function ($$v) {_vm.$set(_vm.signSetForm, "reserved_status", $$v)},expression:"signSetForm.reserved_status"}})],1)]),_c('vh-form-item',{attrs:{"label":"标识","prop":"view_status"}},[_c('div',{staticClass:"switch__box"},[_c('vh-switch',{attrs:{"active-value":1,"inactive-value":0,"active-color":"#FB3A32","inactive-color":"#CECECE","active-text":_vm.signSetForm.view_status
                  ? '已开启，观看页显示品牌标识'
                  : '开启后，观看页显示品牌标识'},model:{value:(_vm.signSetForm.view_status),callback:function ($$v) {_vm.$set(_vm.signSetForm, "view_status", $$v)},expression:"signSetForm.view_status"}})],1)]),_c('vh-form-item',{attrs:{"label":"图片","prop":"logo_url"}},[_c('upload',{staticClass:"upload__sign",attrs:{"domain_url":_vm.domain_url,"saveData":{
              path: 'interacts/skin-imgs',
              type: 'image'
            },"heightImg":130,"widthImg":231,"on-success":_vm.handleUploadSuccess,"on-progress":_vm.uploadProcess,"on-error":_vm.uploadError,"on-preview":_vm.uploadPreview,"before-upload":_vm.beforeUploadHandler},on:{"delete":_vm.resetLogoUrl},model:{value:(_vm.signSetForm.logo_url),callback:function ($$v) {_vm.$set(_vm.signSetForm, "logo_url", $$v)},expression:"signSetForm.logo_url"}},[_c('div',{attrs:{"slot":"tip"},slot:"tip"},[_c('p',[_vm._v("建议尺寸：120*44px，小于2M")]),_c('p',[_vm._v("支持jpg、gif、png、bmp")])])])],1),_c('vh-form-item',{staticClass:"item--skip__url",attrs:{"label":"链接","prop":"skip_url"}},[_c('vh-input',{attrs:{"placeholder":"请输入http://或https://开头的链接"},model:{value:(_vm.signSetForm.skip_url),callback:function ($$v) {_vm.$set(_vm.signSetForm, "skip_url", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"signSetForm.skip_url"}})],1),_c('vh-form-item',{attrs:{"label":""}},[_c('vh-button',{directives:[{name:"preventReClick",rawName:"v-preventReClick"}],attrs:{"type":"primary","round":""},on:{"click":function($event){$event.preventDefault();$event.stopPropagation();return _vm.signSetSave.apply(null, arguments)}}},[_vm._v(" 保 存 ")])],1)],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.brandType == 1 && _vm.brandConfig == 2),expression:"brandType == 1 && brandConfig == 2"}],staticClass:"hide-white"})],1),_c('brand-set-preview',{ref:"brandSetPreviewComp",staticClass:"brand--preview",attrs:{"brandType":_vm.brandType,"tabType":'signSet'}})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }